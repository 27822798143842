import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router";

import './index.css';

import App from './App';

const root = ReactDOM.createRoot(
    document.getElementById('root')
);

root.render(<BrowserRouter >
  <App />
</BrowserRouter >);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
